import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseComponent } from "../../BaseComponent";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";

declare var js3q;

@Component({
    selector: "app-pressplenum",
    templateUrl: "./plenum.component.html",
    styleUrls: ["./plenum.component.scss"]
})
export class PressPlenumComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    public js3qVideoPlayer: any = null;
    public meetpsVisible = false;
    public meetpsUrl;

    private id: string = "";
    private validIds: string[] = [
        "82FZNqb2M9sbhNtqSmxWHTtTcGGuGjVahVSjgKesQXmyLhY8fQcsUcZLp5nLaEHP",
        "zGH4AwuyuyFZMywD9Xu2YRxu2YjdknG25pVg9Yx4KHkWTSzGLsvaY2AkxKV72Vf4",
        "BebtapS3yRqPkzPRH4dTM9UKfFqgYRxEbGqBtmybW3rvvEtXEzv759JjXJ5rW8ZA",
        "DnVts96LDRJmCKqvJmRzTeUfAbtjp3AnC2nG7ZuEcRLRFUVMBjBZkKhR2RhEjWTS",
        "W8yTHKWaApVfZCjXpVbR5eb7jwEL6TFFWwpLvHpD9EPzTMD7xrfce5SRVbeKzpDT",
        "bLbFmCvCQyKJBBQv9WHxQKsEYB49pGCVePZ7yhACLHRXthZRQtw8phdrm4tNvEq9",
        "K2DMJqSpMf9ZrQzMhnA3PMxyDYbHhgkmd7gaKAnG2fjq7BekyEYQ9ARLktXegPBZ",
        "fZnfva2SEBVQAgBfee62LRwfF8haU4EXtNhxNKQW2AqYDFGmJtW49NWqXTcNEsJQ",
        "MQq2JMyEDLn79QK78AJerPvkDYGY43bNq3thB3hHg3TYv8PrAPKzkjv24BhgmEdg",
        "czP3TMhwkGVuZw3wWfMBRLZQBYYhzFU7ty6RCLer47dPnCumHq9pXcYTg3xrzQJT",
        "bV3ZpF4qxPsfQYTuZ4wRP96ZaAMAdHTFsqF3xHVJQpttrrsDndz2u7wsTuXpuQRZ",
        "ehUu8ny2dXaeWFbHG3zPwwpuEeWYHC5bEZCeyZhqKtNzaYWSAQvURG4vTzBWVHt4",
        "GRnjH3g4bmYCabSDx7HcK8quPez3Kkr2sSwcuS4ScGqkqGrsbT5ZnADwgprfkt84",
        "RmmCddpq4Zy8492FjfCxYZbXC2SfHKamGfFZQVtZ5VXPjvNK38DRAXsfLZgvhsZv",
        "J9kEqc5BE4zrnjmQBZdJNwuQYq7MSSTWFvXt4ybv4LNgb2H2XHL9vHEMGaWyQZDT",
        "xxmbTDXrLp8Zf2HK8KLT6ZDDxnsVHJCpTHsev9n2m7NaLWhQHD82eKhjgwL5ATm6",
        "v6P8rVmMrCXytnTYC5rKQ4e337Gy2GaLKzB5f9B3PEf9KABC8LY2kBtNEKEYGN6F",
        "TFYNwkLVexE3UfyJCaPFqjt22vZxH7fKCrnyRAmn5Wtawk3L6SUDdbgBvPP8XWdj",
        "LEFkDHPEV2dnqqZJqRSurngF2NXGj7BkrU7Sh2rVM5uBq4KSrXTcSLBTUvydcv7C",
        "Zuwkrg3ZrbjLP4uJXhJVKXnDT4ptyLBNvKKcpZMVSEmZauC7Aur2JFYBkeAN8bJA",
        "qnHqBWBADnk6fmK8RhTTkQAkTsKtFvsRHxUv9Djc5FXHF5j93AVSWsZSw36MLegD",
        "XUUrXdZ8haTkJq2BS2aVCeJBnLW3DpmgNHeWA8ksptwMP5D6qSEvRgBQhwzXymG3",
        "j4LZBkNdCQfrdj6aHgUZjWPGeAmHDqb2TkFrurpaJg9CKrdaBy3VJr8ve8fYbeYa",
        "C4mEUhAg9yCdve89cNKeqEYPcF3GaL6SspNuDcptfLbzAMJhW7jyWGdMkaYccD4e"
    ];

    constructor(private router: Router, private sanitizer: DomSanitizer, protected route: ActivatedRoute) {
        super();

        this.id = this.route.snapshot.paramMap.get("id");
        if (this.validIds.indexOf(this.id) < 0) {
            this.router.navigate(["/404"]);
            return;
        }
        this.meetpsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.pressRoomMeetpsUrl);
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        const _js3qi = setInterval(() => {
            if (typeof js3q !== "undefined") {
                clearInterval(_js3qi);
                this.js3qVideoPlayer = new js3q({
                    dataid: environment.pressRoomId3qsdn,
                    container: "webinarPlayer"
                });
            }
        }, 10);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    toStart() {
        this.router.navigate(["/press/" + this.id + "/home"]);
    }

    toggleMeetps() {
        this.meetpsVisible = !this.meetpsVisible;
    }
}
