import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { IOrganizationSettings, OAuthStorageAdapter } from "../../OAuthStorageAdapter";
import { BaseComponent } from "../BaseComponent";
import { DomSanitizer } from "@angular/platform-browser";
import { LosTrackingService } from "../../services/lostracking.service";
import { AuthenticationService } from "../../services/authentication.service";

declare var js3q;

@Component({
    selector: "app-plenum",
    templateUrl: "./plenum.component.html",
    styleUrls: ["./plenum.component.scss"]
})
export class PlenumComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    public js3qVideoPlayer: any = null;
    public meetpsVisible = false;
    public meetpsUrl;

    public organizationSettings: IOrganizationSettings;

    constructor(private oauthStorageAdapter: OAuthStorageAdapter, private router: Router, private sanitizer: DomSanitizer, private losTrackingService: LosTrackingService, private authenticationService: AuthenticationService) {
        super();

        const auth = this.oauthStorageAdapter.getCurrentOAuthResult();
        this.organizationSettings = this.oauthStorageAdapter.getOrganizationSettings();

        this.meetpsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.organizationSettings.plenumMeetPS);
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        const _js3qi = setInterval(() => {
            if (typeof js3q !== "undefined") {
                clearInterval(_js3qi);
                this.js3qVideoPlayer = new js3q({
                    dataid: this.organizationSettings.id3qsdn,
                    container: "webinarPlayer"
                });
            }
        }, 10);

        const auth = this.oauthStorageAdapter.getCurrentOAuthResult().orDefault(null);
        const jwt = auth.jwt ?? "";

        this.subscriptions.push(
            this.losTrackingService.cloudflareLookup().subscribe(
                (result) => {
                    const data: any = result
                        .trim()
                        .split("\n")
                        .reduce((obj, pair: any) => {
                            pair = pair.split("=");
                            return (obj[pair[0]] = pair[1]), obj;
                        }, {});

                    this.losTrackingService.setupSocketConnection(jwt, "plenum", data?.loc);
                },
                (err) => {
                    this.losTrackingService.setupSocketConnection(jwt, "plenum", undefined);
                }
            )
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.losTrackingService.closeSocketConnection();
    }

    toStart() {
        this.router.navigate(["/start"]);
    }

    toggleMeetps() {
        this.meetpsVisible = !this.meetpsVisible;
    }
}
