<div id="plenum">
    <!-- [style.top]="this.webinarPlayerTop" [style.left]="this.webinarPlayerLeft" -->
    <div id="webinarPlayerContainer" [class.meetps-visible]="meetpsVisible">
        <div id="webinarPlayer"></div>
    </div>
        
    <div class="meetps-iframe" [class.meetps-visible]="meetpsVisible">
        <iframe [src]="meetpsUrl"></iframe>
    </div>

    <button class="gsk-btn bottom-left-nav-btn" mat-raised-button (click)="toStart()">
        <mat-icon class="back-btn">navigate_before</mat-icon>
        {{ 'plenum.buttons.start' | translate }}
    </button>

    <div class="bottom-right-nav-btn">
        <button class="gsk-btn" style="margin-left: 25px;" mat-raised-button (click)="toggleMeetps()">
            {{ 'plenum.buttons.meetpsToggle' | translate }}
        </button>
    </div>

</div>
