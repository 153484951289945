import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { TawkService } from "src/app/services/tawk.service";

@Component({
    selector: "app-pressroom-layout",
    templateUrl: "./pressroom-layout.component.html",
    styleUrls: ["./pressroom-layout.component.scss"]
})
export class PressRoomLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
    public agendaVisible = false;

    constructor(private tawkService: TawkService) {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.tawkService.SetChatVisibility(true);
    }

    public toggleAgenda() {
        // this.agendaVisible = !this.agendaVisible;
        window.open("/assets/panos/pressroom/assets/docs/1_GSK_Agenda_Pressekonferenz_03_12_2021.pdf", "_blank");
    }

    toggleTawkTo() {
        this.tawkService.ExpandChatWindow(true);
    }

    ngOnDestroy(): void {
        this.tawkService.SetChatVisibility(false);
    }
}
