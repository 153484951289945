<div id="compagnonContainer">
    <img id="nucalaLogo" src="/assets/img/nucala-logo.png" />
    <div id="innerCompagnonContainer">
        <button mat-raised-button class="compagnon-btn" (click)="toRoomOverview()">
            Q&amp;A - Räume
        </button>

        <button mat-raised-button class="compagnon-btn" (click)="openAgenda()">
            Agenda
        </button>

        <button mat-raised-button class="compagnon-btn" (click)="openFloorplan()">
            Floorplan
        </button>
    </div>
</div>