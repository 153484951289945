import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Injector, APP_INITIALIZER } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FastLoginComponent } from "./components/fastlogin/fastlogin.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthenticationGuard } from "./AuthenticationGuard";
import { IEGuard } from "./IEGuard";
import { OAuthStorageAdapter } from "./OAuthStorageAdapter";
import { TranslateModule, TranslateService, TranslateLoader } from "@ngx-translate/core";
import { translateLoaderService } from "./global/translate/translateloader.service";
import { LOCATION_INITIALIZED } from "@angular/common";
import { AlertComponent } from "./components/alert/alert.component";
import { HttpErrorInterceptor } from "./http-error.interceptor";
import { SingleLayoutComponent } from "./components/single-layout/single-layout.component";
import { NullLayoutComponent } from "./components/null-layout/null-layout.component";
import { FullLayoutComponent } from "./components/full-layout/full-layout.component";
import { PressRoomLayoutComponent } from "./components/pressroom-layout/pressroom-layout.component";
import { AboutComponent } from "./components/about/about.component";
import { DataLegalComponent } from "./components/data-legal/data-legal.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { RouteReuseStrategy } from "@angular/router";
import { CustomRouteReuseStrategy } from "./global/CustomRouteReuseStrategy";
import { AlertDialogComponent } from "./components/alert-dialog/alert-dialog.component";
import { NoIEComponent } from "./components/noie/noie.component";
import { MatDialogModule } from "@angular/material/dialog";
import { LosTrackingService } from "./services/lostracking.service";
import { StartComponent } from "./components/start/start.component";
import { PlenumComponent } from "./components/plenum/plenum.component";
import { WorkshopComponent } from "./components/workshop/workshop.component";
import { DownloadComponent } from "./components/download/download.component";
import { CompagnonHomeComponent } from "./components/compagnon/home/home.component";
import { CompagnonRoomsComponent } from "./components/compagnon/rooms/rooms.component";
import { CompagnonRoomComponent } from "./components/compagnon/room/room.component";
import { PressHomeComponent } from "./components/press/home/home.component";
import { PressPlenumComponent } from "./components/press/plenum/plenum.component";
import { RoomSelectionComponent } from "./components/roomselection/roomselection.component";
import { TawkService } from "./services/tawk.service";

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                translate.addLangs(["en"]);

                const langToSet = "en";
                translate.setDefaultLang("en");
                translate.use(langToSet).subscribe(
                    () => {
                        // language initialized
                    },
                    (err) => {
                        console.error(`Problem with '${langToSet}' language initialization.'`);
                    },
                    () => {
                        resolve(null);
                    }
                );
            });
        });
}

export const httpInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }];
@NgModule({
    declarations: [
        AppComponent,
        FastLoginComponent,
        AlertComponent,
        AboutComponent,
        DataLegalComponent,
        SingleLayoutComponent,
        NullLayoutComponent,
        FullLayoutComponent,
        PressRoomLayoutComponent,
        AlertDialogComponent,
        NoIEComponent,
        StartComponent,
        PlenumComponent,
        WorkshopComponent,
        DownloadComponent,
        CompagnonHomeComponent,
        CompagnonRoomsComponent,
        CompagnonRoomComponent,
        PressHomeComponent,
        PressPlenumComponent,
        RoomSelectionComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        BrowserAnimationsModule,
        MatIconModule,
        MatListModule,
        MatDividerModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule
    ],
    providers: [
        AuthenticationGuard,
        LosTrackingService,
        IEGuard,
        OAuthStorageAdapter,
        httpInterceptorProviders,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
        {
            provide: TranslateLoader,
            useFactory: translateLoaderService,
            deps: [HttpClient]
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        },
        TawkService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
