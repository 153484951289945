import { Component, OnInit } from "@angular/core";
import { OrganizationBaseComponent } from "../OrganizationBaseComponent";
import { AuthenticationService } from "../../services/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IOAuthLoginResult } from "../../models/IOAuthLoginResult";
import { OAuthStorageAdapter } from "../../OAuthStorageAdapter";
@Component({
    selector: "app-fastlogin",
    template: `<div></div>`
})
export class FastLoginComponent extends OrganizationBaseComponent implements OnInit {
    constructor(private authenticationService: AuthenticationService, protected router: Router, private oauthStorageAdapter: OAuthStorageAdapter, protected route: ActivatedRoute) {
        super(router, route);
    }

    ngOnInit(): void {
        super.ngOnInit();
        const token = this.route.snapshot.paramMap.get("token");
        this.subscriptions.push(
            this.authenticationService.fastlogin(token, this.organizationKey).subscribe(
                (result: IOAuthLoginResult) => {
                    this.oauthStorageAdapter.setCurrentOauthResult(result);
                    this.oauthStorageAdapter.setCurrentOrganizationKey(this.organizationKey);
                    this.router.navigate(["/start"]);
                },
                (err) => {
                    this.router.navigate(["/404"]);
                }
            )
        );
    }
}
