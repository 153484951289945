<div id="compagnonContainer">
    <img id="nucalaLogo" src="/assets/img/nucala-logo.png" />
    <div id="innerCompagnonContainer">
        <button mat-raised-button class="compagnon-btn" (click)="toRoom(6)">
            PLENUM
        </button>

        <button mat-raised-button class="compagnon-btn" (click)="toRoom(1)">
            Workshop CRSwNP
        </button>

        <button mat-raised-button class="compagnon-btn" (click)="toRoom(2)">
            Workshop HES
        </button>

        <button mat-raised-button class="compagnon-btn" (click)="toRoom(3)">
            Workshop EGPA
        </button>

        <button mat-raised-button class="compagnon-btn" (click)="toRoom(4)">
            Workshop Gesprächsführung
        </button>

        <button mat-raised-button class="compagnon-btn" (click)="toRoom(5)">
            Workshop digitale Trends
        </button>

        <button mat-raised-button class="compagnon-btn" (click)="toRoom(6)">
            Workshop Versorgungssituation Asthma
        </button>

    </div>
</div>