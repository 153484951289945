<router-outlet>
</router-outlet>

<div id="agendaBackground" *ngIf="agendaVisible" (click)="toggleAgenda()"></div>

<div id="agendaFrame" *ngIf="agendaVisible">
    <iframe src="https://online.fliphtml5.com/elkzy/ttnb/"></iframe>
</div>

<button *ngIf="agendaVisible" id="agendaFrameCloseButton" (click)="toggleAgenda()" title="Close">
    <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
</button>


<button class="nucala-btn mt-3 ml-3" mat-raised-button (click)="toggleAgenda()">
    {{ "global.buttons.agenda" | translate }}
</button>

<div class="disclaimerBackground" *ngIf="showDisclaimer"></div>
<div class="disclaimerContainer" *ngIf="showDisclaimer">
    <h1>{{ disclaimerSettings.i18nTitleKey | translate }}</h1>
    <p [innerHTML]="disclaimerSettings.i18nContentKey | translate"></p>
    <div class="disclaimerButtonWrapper">
        <span (click)="agreeDisclaimer()">{{ disclaimerSettings.i18nAgreeButtonKey | translate }}</span>
    </div>
</div>

<div class="supportFooter"><a href="https://de.gsk.com/de-de/impressum/" target="_blank">Impressum</a> | <a href="https://de.gsk.com/de-de/nutzungsbedingungen/" target="_blank">Nutzungsbedingungen</a> | <a href="https://privacy.gsk.com/de-de/privacy-notice/general/" target="_blank">Datenschutzbestimmungen</a> | <a href="https://de.gsk.com/de-de/uber-uns/" target="_blank">Über GSK</a> | <a href="https://gskpro.com/de-de/kontakt/" target="_blank">Kontakt/Nebenwirkungen melden</a></div>