import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthenticationGuard } from "./AuthenticationGuard";
import { IEGuard } from "./IEGuard";
import { FullLayoutComponent } from "./components/full-layout/full-layout.component";
import { SingleLayoutComponent } from "./components/single-layout/single-layout.component";
import { NullLayoutComponent } from "./components/null-layout/null-layout.component";
import { AboutComponent } from "./components/about/about.component";
import { DataLegalComponent } from "./components/data-legal/data-legal.component";
import { FastLoginComponent } from "./components/fastlogin/fastlogin.component";
import { NoIEComponent } from "./components/noie/noie.component";
import { StartComponent } from "./components/start/start.component";
import { PlenumComponent } from "./components/plenum/plenum.component";
import { WorkshopComponent } from "./components/workshop/workshop.component";
import { DownloadComponent } from "./components/download/download.component";
import { CompagnonHomeComponent } from "./components/compagnon/home/home.component";
import { CompagnonRoomsComponent } from "./components/compagnon/rooms/rooms.component";
import { CompagnonRoomComponent } from "./components/compagnon/room/room.component";
import { PressRoomLayoutComponent } from "./components/pressroom-layout/pressroom-layout.component";
import { PressHomeComponent } from "./components/press/home/home.component";
import { PressPlenumComponent } from "./components/press/plenum/plenum.component";
import { RoomSelectionComponent } from "./components/roomselection/roomselection.component";

const routes: Routes = [
    {
        path: "",
        component: SingleLayoutComponent,
        canActivate: [AuthenticationGuard, IEGuard],
        children: [
            {
                path: "",
                redirectTo: "start",
                pathMatch: "full"
            },
            {
                path: "start",
                component: StartComponent,
                pathMatch: "full"
            },
            {
                path: "rooms",
                component: RoomSelectionComponent,
                pathMatch: "full"
            },
            {
                path: "plenum",
                component: PlenumComponent,
                pathMatch: "full"
            },
            {
                path: "workshop/:id",
                component: WorkshopComponent,
                pathMatch: "full"
            },
            {
                path: "download",
                component: DownloadComponent,
                pathMatch: "full"
            }
        ]
    },
    {
        path: "",
        component: FullLayoutComponent,
        canActivate: [IEGuard],
        children: [{ path: "enter/:token", component: FastLoginComponent, pathMatch: "full" }]
    },
    {
        path: "",
        component: PressRoomLayoutComponent,
        children: [
            {
                path: "press/:id/home",
                component: PressHomeComponent,
                pathMatch: "full"
            },
            {
                path: "press/:id/plenum",
                component: PressPlenumComponent,
                pathMatch: "full"
            }
        ]
    },
    {
        path: "",
        component: NullLayoutComponent,
        children: [
            {
                path: "compagnon/home",
                component: CompagnonHomeComponent,
                pathMatch: "full"
            },
            {
                path: "compagnon/rooms",
                component: CompagnonRoomsComponent,
                pathMatch: "full"
            },
            {
                path: "compagnon/rooms/:id",
                component: CompagnonRoomComponent,
                pathMatch: "full"
            }
        ]
    },
    {
        path: "",
        component: FullLayoutComponent,
        children: [
            { path: "data_legal", component: DataLegalComponent, pathMatch: "full" },
            { path: "imprint", component: AboutComponent, pathMatch: "full" },
            { path: "noie", component: NoIEComponent, pathMatch: "full" },
            {
                path: "404",
                component: AboutComponent,
                pathMatch: "full"
            },
            {
                path: "**",
                redirectTo: "/404"
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
