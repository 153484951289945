import { Component, OnInit, AfterViewInit, OnDestroy, NgZone } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LosTrackingService } from "src/app/services/lostracking.service";
import { IOrganizationSettings, OAuthStorageAdapter } from "../../OAuthStorageAdapter";
import { BaseComponent } from "../BaseComponent";

declare const embedpano: any;
declare const clicktracker: any;

@Component({
    selector: "app-start",
    templateUrl: "./start.component.html",
    styleUrls: ["./start.component.scss"]
})
export class StartComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    public organizationSettings: IOrganizationSettings;
    private loadScene2 = false;

    constructor(private _ngZone: NgZone, private router: Router, private oauthStorageAdapter: OAuthStorageAdapter, private losTrackingService: LosTrackingService, protected route: ActivatedRoute) {
        super();

        window["startComponentRef"] = { component: this, zone: _ngZone };
        this.organizationSettings = this.oauthStorageAdapter.getOrganizationSettings();
        if (this.organizationSettings.clickTracking) {
            clicktracker.setup(this.organizationSettings?.clickTracking?.token, this.organizationSettings?.clickTracking?.endpoint, this.organizationSettings?.clickTracking?.tenantId);
        }

        this.loadScene2 = this.route.snapshot.queryParamMap.get("scene2") === "1";
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        let settings = {
            onstart: "loadscene(pano1, null, MERGE)"
        };

        if (this.loadScene2) {
            settings = {
                onstart: "loadscene(pano2, null, MERGE)"
            };
        }

        embedpano({
            swf: this.organizationSettings.panoSwf,
            xml: this.organizationSettings.panoXml,
            target: "reception-pano",
            html5: "auto",
            mobilescale: 1.0,
            passQueryParameters: true,
            vars: settings
        });

        const auth = this.oauthStorageAdapter.getCurrentOAuthResult().orDefault(null);
        const jwt = auth.jwt ?? "";

        this.subscriptions.push(
            this.losTrackingService.cloudflareLookup().subscribe(
                (result) => {
                    const data: any = result
                        .trim()
                        .split("\n")
                        .reduce((obj, pair: any) => {
                            pair = pair.split("=");
                            return (obj[pair[0]] = pair[1]), obj;
                        }, {});

                    this.losTrackingService.setupSocketConnection(jwt, "start", data?.loc);
                },
                (err) => {
                    this.losTrackingService.setupSocketConnection(jwt, "start", undefined);
                }
            )
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.losTrackingService.closeSocketConnection();
    }

    // Usage in KRPano => onclick="jscall(clickSpot('BoC - SGO 2020'));"
    clickSpot(elementName) {
        clicktracker.click(elementName);
    }

    // toRoomSelection() {
    //     this.router.navigate(["/rooms"]);
    // }

    toPlenum() {
        this.router.navigate(["/plenum"]);
    }

    toWorkshop(num: number) {
        this.router.navigate(["/workshop", num]);
    }
}
