<div id="startroom">
    <button id="buttonPlenum" mat-raised-button class="nucala-btn startButton" (click)="toPlenum()">
        {{ 'start.buttons.plenum' | translate }}
    </button>

    <button id="buttonWorkshop1" mat-raised-button class="gsk-btn startButton" (click)="toWorkshop(1)" title="{{ 'start.alt.workshop1' | translate }}" [disabled]="!enableWorkshopButtons">
        {{ 'start.buttons.workshop1' | translate }}
    </button>

    <button id="buttonWorkshop2" mat-raised-button class="gsk-btn startButton" (click)="toWorkshop(2)" title="{{ 'start.alt.workshop2' | translate }}" [disabled]="!enableWorkshopButtons">
        {{ 'start.buttons.workshop2' | translate }}
    </button>

    <button id="buttonWorkshop3" mat-raised-button class="gsk-btn startButton" (click)="toWorkshop(3)" title="{{ 'start.alt.workshop3' | translate }}" [disabled]="!enableWorkshopButtons">
        {{ 'start.buttons.workshop3' | translate }}
    </button>

    <button id="buttonWorkshop4" mat-raised-button class="gsk-btn startButton" (click)="toWorkshop(4)" title="{{ 'start.alt.workshop4' | translate }}" [disabled]="!enableWorkshopButtons">
        {{ 'start.buttons.workshop4' | translate }}
    </button>

    <button id="buttonWorkshop5" mat-raised-button class="gsk-btn startButton" (click)="toWorkshop(5)" title="{{ 'start.alt.workshop5' | translate }}" [disabled]="!enableWorkshopButtons">
        {{ 'start.buttons.workshop5' | translate }}
    </button>

    <button id="buttonWorkshop6" mat-raised-button class="gsk-btn startButton" (click)="toPlenum()" title="{{ 'start.alt.workshop6' | translate }}" [disabled]="!enableWorkshopButtons">
        {{ 'start.buttons.workshop6' | translate }}
    </button>

    <button class="gsk-btn bottom-left-nav-btn" mat-raised-button (click)="toStart()">
        <mat-icon class="back-btn">navigate_before</mat-icon>
        {{ 'plenum.buttons.start' | translate }}
    </button>
</div>

<div class="ewerkFooter">{{ "global.ewerkFooter" | translate }}</div>