import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { LosTrackingService } from "src/app/services/lostracking.service";
import { environment } from "src/environments/environment";
import { IOrganizationSettings, OAuthStorageAdapter } from "../../OAuthStorageAdapter";
import { BaseComponent } from "../BaseComponent";

@Component({
    selector: "app-roomselection",
    templateUrl: "./roomselection.component.html",
    styleUrls: ["./roomselection.component.scss"]
})
export class RoomSelectionComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    public organizationSettings: IOrganizationSettings;

    public enableWorkshopButtons = false;

    constructor(private router: Router, private oauthStorageAdapter: OAuthStorageAdapter, private losTrackingService: LosTrackingService) {
        super();

        this.organizationSettings = this.oauthStorageAdapter.getOrganizationSettings();

        const disableWorkshopButtonsUntil = moment(environment.disableWorkshopButtonsUntil, environment.disableWorkshopButtonsUntilTimeformat);
        const now = moment();

        if (now.isAfter(disableWorkshopButtonsUntil)) {
            this.enableWorkshopButtons = true;
        } else {
            const disableWorkshopButtonsInterval = setInterval(() => {
                if (moment().isAfter(disableWorkshopButtonsUntil)) {
                    this.enableWorkshopButtons = true;
                    clearInterval(disableWorkshopButtonsInterval);
                }
            }, 10000);
        }
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        const auth = this.oauthStorageAdapter.getCurrentOAuthResult().orDefault(null);
        const jwt = auth.jwt ?? "";

        this.subscriptions.push(
            this.losTrackingService.cloudflareLookup().subscribe(
                (result) => {
                    const data: any = result
                        .trim()
                        .split("\n")
                        .reduce((obj, pair: any) => {
                            pair = pair.split("=");
                            return (obj[pair[0]] = pair[1]), obj;
                        }, {});

                    this.losTrackingService.setupSocketConnection(jwt, "roomselection", data?.loc);
                },
                (err) => {
                    this.losTrackingService.setupSocketConnection(jwt, "roomselection", undefined);
                }
            )
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.losTrackingService.closeSocketConnection();
    }

    toPlenum() {
        this.router.navigate(["/plenum"]);
    }

    toWorkshop(num: number) {
        this.router.navigate(["/workshop", num]);
    }

    toStart() {
        this.router.navigate(["/start"]);
    }
}
