import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "../../BaseComponent";

@Component({
    selector: "app-compagnon-rooms",
    templateUrl: "./rooms.component.html",
    styleUrls: ["./rooms.component.scss"]
})
export class CompagnonRoomsComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(private router: Router) {
        super();
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    toRoom(id) {
        this.router.navigate(["/compagnon/rooms/", id]);
    }
}
