import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import * as moment from "moment";
import { IOrganizationSettings, OAuthStorageAdapter } from "src/app/OAuthStorageAdapter";
import { TawkService } from "src/app/services/tawk.service";

@Component({
    selector: "app-single-layout",
    templateUrl: "./single-layout.component.html",
    styleUrls: ["./single-layout.component.scss"]
})
export class SingleLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
    public agendaVisible = false;
    public showDisclaimer = false;
    public disclaimerSettings;

    private settings: IOrganizationSettings | null = null;
    private organizationKey = "";

    constructor(private oauthStorageAdapter: OAuthStorageAdapter, private tawkService: TawkService) {}

    ngOnInit(): void {
        this.settings = this.oauthStorageAdapter.getOrganizationSettings();
        this.decideToShowDisclaimer();
    }

    ngAfterViewInit() {
        this.tawkService.SetChatVisibility(true);
    }

    public toggleAgenda() {
        // this.agendaVisible = !this.agendaVisible;
        window.open("/assets/docs/agenda.pdf", "_blank");
    }

    decideToShowDisclaimer() {
        // if (this.settings?.postLoginDisclaimer?.show) {
        //     this.disclaimerSettings = this.settings.postLoginDisclaimer;
        //     this.organizationKey = this.settings.key;
        //     const disclaimerAcceptedDateString = localStorage.getItem(`${this.organizationKey}-disclaimer-accepted`);
        //     if (!disclaimerAcceptedDateString) {
        //         this.showDisclaimer = true;
        //         return;
        //     }
        //     try {
        //         const disclaimerAcceptedDate = moment(disclaimerAcceptedDateString);
        //         if (!disclaimerAcceptedDate.isValid()) {
        //             this.showDisclaimer = true;
        //             return;
        //         }
        //         const timeDiffInMonths = moment().diff(disclaimerAcceptedDate, "months");
        //         if (timeDiffInMonths >= 1) {
        //             this.showDisclaimer = true;
        //         }
        //     } catch (exc) {
        //         this.showDisclaimer = true;
        //         console.log(exc);
        //     }
        // }
    }

    agreeDisclaimer() {
        localStorage.setItem(`${this.organizationKey}-disclaimer-accepted`, String(moment().format()));
        this.showDisclaimer = false;
    }

    toggleTawkTo() {
        this.tawkService.ExpandChatWindow(true);
    }

    ngOnDestroy(): void {
        this.tawkService.SetChatVisibility(false);
    }
}
