import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "../../BaseComponent";

@Component({
    selector: "app-compagnon-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"]
})
export class CompagnonHomeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(private router: Router) {
        super();
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    toRoomOverview() {
        this.router.navigate(["/compagnon/rooms"]);
    }

    openAgenda() {
        window.open("/assets/docs/agenda.pdf", "_blank");
    }

    openFloorplan() {
        window.open("/assets/docs/floorplan.pdf", "_blank");
    }
}
