import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { BaseComponent } from "../../BaseComponent";

@Component({
    selector: "app-compagnon-room",
    templateUrl: "./room.component.html",
    styleUrls: ["./room.component.scss"]
})
export class CompagnonRoomComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    public workshopId: string;
    public meetpsUrl;
    private validWorkshopIds = ["1", "2", "3", "4", "5", "6"]; // 6 == Plenum

    constructor(private router: Router, private sanitizer: DomSanitizer, protected route: ActivatedRoute) {
        super();

        this.workshopId = this.route.snapshot.paramMap.get("id");
        if (this.validWorkshopIds.indexOf(this.workshopId) < 0) {
            this.router.navigate(["/compagnon/rooms"]);
            return;
        }

        if (this.workshopId === "6") {
            this.meetpsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.organizations[0]["plenumMeetPS"]);
        } else {
            this.meetpsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.organizations[0]["workshopMeetPS" + this.workshopId]);
        }
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
