import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { IOrganizationSettings, OAuthStorageAdapter } from "../../OAuthStorageAdapter";
import { BaseComponent } from "../BaseComponent";

@Component({
    selector: "app-download",
    templateUrl: "./download.component.html",
    styleUrls: ["./download.component.scss"]
})
export class DownloadComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    public organizationSettings: IOrganizationSettings;

    constructor(private router: Router, private oauthStorageAdapter: OAuthStorageAdapter) {
        super();

        this.organizationSettings = this.oauthStorageAdapter.getOrganizationSettings();
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    toStart() {
        this.router.navigate(["/start"]);
    }
}
