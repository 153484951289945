import { Component, OnInit, AfterViewInit, OnDestroy, NgZone } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { BaseComponent } from "../../BaseComponent";

declare const embedpano: any;

@Component({
    selector: "app-presshome",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"]
})
export class PressHomeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    private id: string = "";
    private validIds: string[] = [
        "82FZNqb2M9sbhNtqSmxWHTtTcGGuGjVahVSjgKesQXmyLhY8fQcsUcZLp5nLaEHP",
        "zGH4AwuyuyFZMywD9Xu2YRxu2YjdknG25pVg9Yx4KHkWTSzGLsvaY2AkxKV72Vf4",
        "BebtapS3yRqPkzPRH4dTM9UKfFqgYRxEbGqBtmybW3rvvEtXEzv759JjXJ5rW8ZA",
        "DnVts96LDRJmCKqvJmRzTeUfAbtjp3AnC2nG7ZuEcRLRFUVMBjBZkKhR2RhEjWTS",
        "W8yTHKWaApVfZCjXpVbR5eb7jwEL6TFFWwpLvHpD9EPzTMD7xrfce5SRVbeKzpDT",
        "bLbFmCvCQyKJBBQv9WHxQKsEYB49pGCVePZ7yhACLHRXthZRQtw8phdrm4tNvEq9",
        "K2DMJqSpMf9ZrQzMhnA3PMxyDYbHhgkmd7gaKAnG2fjq7BekyEYQ9ARLktXegPBZ",
        "fZnfva2SEBVQAgBfee62LRwfF8haU4EXtNhxNKQW2AqYDFGmJtW49NWqXTcNEsJQ",
        "MQq2JMyEDLn79QK78AJerPvkDYGY43bNq3thB3hHg3TYv8PrAPKzkjv24BhgmEdg",
        "czP3TMhwkGVuZw3wWfMBRLZQBYYhzFU7ty6RCLer47dPnCumHq9pXcYTg3xrzQJT",
        "bV3ZpF4qxPsfQYTuZ4wRP96ZaAMAdHTFsqF3xHVJQpttrrsDndz2u7wsTuXpuQRZ",
        "ehUu8ny2dXaeWFbHG3zPwwpuEeWYHC5bEZCeyZhqKtNzaYWSAQvURG4vTzBWVHt4",
        "GRnjH3g4bmYCabSDx7HcK8quPez3Kkr2sSwcuS4ScGqkqGrsbT5ZnADwgprfkt84",
        "RmmCddpq4Zy8492FjfCxYZbXC2SfHKamGfFZQVtZ5VXPjvNK38DRAXsfLZgvhsZv",
        "J9kEqc5BE4zrnjmQBZdJNwuQYq7MSSTWFvXt4ybv4LNgb2H2XHL9vHEMGaWyQZDT",
        "xxmbTDXrLp8Zf2HK8KLT6ZDDxnsVHJCpTHsev9n2m7NaLWhQHD82eKhjgwL5ATm6",
        "v6P8rVmMrCXytnTYC5rKQ4e337Gy2GaLKzB5f9B3PEf9KABC8LY2kBtNEKEYGN6F",
        "TFYNwkLVexE3UfyJCaPFqjt22vZxH7fKCrnyRAmn5Wtawk3L6SUDdbgBvPP8XWdj",
        "LEFkDHPEV2dnqqZJqRSurngF2NXGj7BkrU7Sh2rVM5uBq4KSrXTcSLBTUvydcv7C",
        "Zuwkrg3ZrbjLP4uJXhJVKXnDT4ptyLBNvKKcpZMVSEmZauC7Aur2JFYBkeAN8bJA",
        "qnHqBWBADnk6fmK8RhTTkQAkTsKtFvsRHxUv9Djc5FXHF5j93AVSWsZSw36MLegD",
        "XUUrXdZ8haTkJq2BS2aVCeJBnLW3DpmgNHeWA8ksptwMP5D6qSEvRgBQhwzXymG3",
        "j4LZBkNdCQfrdj6aHgUZjWPGeAmHDqb2TkFrurpaJg9CKrdaBy3VJr8ve8fYbeYa",
        "C4mEUhAg9yCdve89cNKeqEYPcF3GaL6SspNuDcptfLbzAMJhW7jyWGdMkaYccD4e"
    ];

    constructor(private _ngZone: NgZone, private router: Router, protected route: ActivatedRoute) {
        super();

        this.id = this.route.snapshot.paramMap.get("id");
        if (this.validIds.indexOf(this.id) < 0) {
            this.router.navigate(["/404"]);
            return;
        }
        window["pressHomeComponentRef"] = { component: this, zone: _ngZone };
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        embedpano({
            swf: environment.pressRoomPanoSwf,
            xml: environment.pressRoomPanoXml,
            target: "reception-pano",
            html5: "auto",
            mobilescale: 1.0,
            passQueryParameters: true
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    toPlenum() {
        this.router.navigate(["press/" + this.id + "/plenum"]);
    }
}
