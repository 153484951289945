import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IOrganizationSettings, OAuthStorageAdapter } from "../../OAuthStorageAdapter";
import { BaseComponent } from "../BaseComponent";
import { DomSanitizer } from "@angular/platform-browser";
import { LosTrackingService } from "../../services/lostracking.service";
import { AuthenticationService } from "../../services/authentication.service";

declare var js3q;

@Component({
    selector: "app-workshop",
    templateUrl: "./workshop.component.html",
    styleUrls: ["./workshop.component.scss"]
})
export class WorkshopComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    public js3qVideoPlayer: any = null;
    public meetpsVisible = false;
    public meetpsUrl;
    public workshopId: string;

    public organizationSettings: IOrganizationSettings;

    private validWorkshopIds = ["1", "2", "3", "4", "5", "6"];

    constructor(
        private oauthStorageAdapter: OAuthStorageAdapter,
        private router: Router,
        private sanitizer: DomSanitizer,
        private losTrackingService: LosTrackingService,
        private authenticationService: AuthenticationService,
        protected route: ActivatedRoute
    ) {
        super();

        this.workshopId = this.route.snapshot.paramMap.get("id");
        if (this.validWorkshopIds.indexOf(this.workshopId) < 0) {
            this.toStart();
            return;
        }

        const auth = this.oauthStorageAdapter.getCurrentOAuthResult();
        this.organizationSettings = this.oauthStorageAdapter.getOrganizationSettings();

        this.meetpsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.organizationSettings["workshopMeetPS" + this.workshopId]);
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        const _js3qi = setInterval(() => {
            if (typeof js3q !== "undefined") {
                clearInterval(_js3qi);
                this.js3qVideoPlayer = new js3q({
                    dataid: this.organizationSettings["id3qsdnWorkshop" + this.workshopId],
                    container: "webinarPlayer"
                });
            }
        }, 10);

        const auth = this.oauthStorageAdapter.getCurrentOAuthResult().orDefault(null);
        const jwt = auth.jwt ?? "";

        this.subscriptions.push(
            this.losTrackingService.cloudflareLookup().subscribe(
                (result) => {
                    const data: any = result
                        .trim()
                        .split("\n")
                        .reduce((obj, pair: any) => {
                            pair = pair.split("=");
                            return (obj[pair[0]] = pair[1]), obj;
                        }, {});

                    this.losTrackingService.setupSocketConnection(jwt, "workshop" + this.workshopId, data?.loc);
                },
                (err) => {
                    this.losTrackingService.setupSocketConnection(jwt, "workshop" + this.workshopId, undefined);
                }
            )
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.losTrackingService.closeSocketConnection();
    }

    toStart() {
        this.router.navigate(["/start"], { queryParams: { scene2: 1 } });
    }

    toggleMeetps() {
        this.meetpsVisible = !this.meetpsVisible;
    }
}
