// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    environmentName: "test",
    version: require("../../package.json").version,
    httpRequestTimeoutSeconds: 120,
    utcDateFormat: "yyyy-MM-dd HH:mm:ss",
    baseAPI: "https://api.gsk.infillqa.com/api/v1/",
    oauthBaseAPI: "https://api.gsk.infillqa.com/api/v1/auth/",
    registrationsAPI: "https://api.gsk.infillqa.com/api/v1/registration/",
    resetPasswordAPI: "https://api.gsk.infillqa.com/api/v1/resetpassword/",
    defaultLanguage: "en",
    webinarTimeformat: "YYYY-MM-DD HH:mm:ss ZZ",
    logErrorInConsole: true,
    disableWorkshopButtonsUntilTimeformat: "YYYY-MM-DD HH:mm:ss ZZ",
    disableWorkshopButtonsUntil: "2020-12-04 05:00:00 +0100",
    organizations: [
        {
            key: "nucala",
            id3qsdn: "6a645fd6-4888-11ec-b839-0cc47a188158",
            id3qsdnWorkshop1: "756750a1-4886-11ec-b839-0cc47a188158",
            id3qsdnWorkshop2: "7dae9b1c-4886-11ec-b839-0cc47a188158",
            id3qsdnWorkshop3: "8ade4615-4886-11ec-b839-0cc47a188158",
            id3qsdnWorkshop4: "b1589ddc-4886-11ec-b839-0cc47a188158",
            id3qsdnWorkshop5: "e5066412-4886-11ec-b839-0cc47a188158",
            id3qsdnWorkshop6: "6a645fd6-4888-11ec-b839-0cc47a188158",
            webinarStart: "2020-10-19 15:19:00 +0200",
            losTrackingEndpoint: "https://lostracking.services.infillqa.com/",
            plenumMeetPS: "https://meet.ps/nucala4balance",
            workshopMeetPS1: "https://meet.ps/4balance-workshop-1",
            workshopMeetPS2: "https://meet.ps/4balance-workshop-2",
            workshopMeetPS3: "https://meet.ps/4balance-workshop-3",
            workshopMeetPS4: "https://meet.ps/4balance-workshop-4",
            workshopMeetPS5: "https://meet.ps/4balance-workshop-5",
            workshopMeetPS6: "https://meet.ps/nucala4balance",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "nucala"
            },
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.nucala.title",
                i18nContentKey: "disclaimer.nucala.content",
                i18nAgreeButtonKey: "disclaimer.nucala.button.agree"
            },
            tawkId: "5fa15019e019ee7748f05489",
            panoSwf: "assets/panos/nucala/assets/js/krpano.swf",
            panoXml: "assets/panos/nucala/pano.xml"
        }
    ],
    pressRoomPanoSwf: "assets/panos/pressroom/assets/js/krpano.swf",
    pressRoomPanoXml: "assets/panos/pressroom/pano.xml",
    pressRoomMeetpsUrl: "https://meet.ps/4balance-press",
    pressRoomId3qsdn: "bf04a138-4df2-11ec-99ef-3cecef385192"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
